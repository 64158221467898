export const locations = [
  {
    id: 1,
    name: "Harsidh Donuts, Inc",
    code: 339785,
    city: "Clarksville",
    address: "12210, Clarksville Pike, Clarksville, MD 21029",
    email: "pc339785@gmail.com",
    phone: "443-535-0600",
  },
  {
    id: 2,
    name: "Durga Donuts, Inc",
    code: 341097,
    city: "Scaggsville",
    address: "8305 Ice Crystal Drive, Unit 1, Laurel, MD 20723",
    email: "pc341097@gmail.com",
    phone: "301-498-3250",
  },

  {
    id: 3,
    name: "Krishna Donuts, Inc",
    code: 343694,
    city: "Route 40",
    address: "9469 Baltimore National Pike #1, Ellicott City, MD 21042-2817",
    email: "pc343694@gmail.com",
    phone: "410-203-1777",
  },
  {
    id: 4,
    name: "Shiva Donuts, Inc",
    code: 344089,
    city: "Woodbine",
    address: "703 Lisbon Center Drive, Woodbine, MD 21797",
    email: "pc344089@gmail.com",
    phone: "410-489-0990",
  },
  {
    id: 5,
    name: "Shri Ram Donuts, Inc",
    code: 345244,
    city: "Route 103",
    address: "4872 Montgomery Rd, Ellicott City, MD 21043",
    email: "pc345244@gmail.com",
    phone: "410-480-8430",
  },
  {
    id: 6,
    name: "Saraswati Donuts, Inc",
    code: 345682,
    city: "John Hopkin",
    address: "7530 Montpelier Rd, Suite 104, Laurel, MD 20723",
    email: "pc345682@gmail.com",
    phone: "301-498-2444",
  },
  {
    id: 7,
    name: "Snowden Donuts, Inc",
    code: 359724,
    city: "Snowden",
    address: "6001 University Blvd, Ellicott City, MD 21043",
    email: "dd359724@gmail.com",
    phone: "410-465-5882",
  },
  {
    id: 8,
    name: "Mt' Airy Donuts, Inc",
    code: 348786,
    city: "Mt Airy",
    address: "1401 South Main St, Suite C, Mt. Airy, MD 21771",
    email: "pc348786@gmail.com",
    phone: "301-829-9005",
  },
  {
    id: 9,
    name: "Damascus Donuts, Inc",
    code: 349916,
    city: "Damascus",
    address: "9803 Main St, Damascus, MD 20872-2015",
    email: "pc349916@gmail.com",
    phone: "301-391-6100",
  },
  {
    id: 10,
    name: "Shaker Donuts, Inc",
    code: 350880,
    city: "Shaker",
    address: "10430 Shaker Drive, Columbia MD 21044",
    email: "pc350880@gmail.com",
    phone: "410-992-6989",
  },
  {
    id: 11,
    name: "Meadowridge Donuts, Inc",
    code: 354683,
    city: "Meadowridge",
    address: "6010 Meadowridge Center Dr, Suite A, Elkridge MD 21075",
    email: "pc354683@gmail.com",
    phone: "410-799-2888",
  },
  {
    id: 12,
    name: "Normandy Donuts, Inc",
    code: 302849,
    city: "Normandy",
    address: "8455 Baltimore National Pike, Suite D, Ellicott City MD 21043",
    email: "pc302849@gmail.com",
    phone: "410-750-6010",
  },
  {
    id: 13,
    name: "Dorsey Donuts, Inc",
    code: 357092,
    city: "Dorsey",
    address: "1741 Dorsey Road, Suite 1 , Hanover, MD 21076",
    email: "pc357092@gmail.com",
    phone: "410-796-6169",
  },
  {
    id: 14,
    name: "Oaklnd Mills Donuts, Inc",
    code: 357632,
    city: "Oakland Mills",
    address: "5880 Robert Oliver Place, Columbia MD 21045",
    email: "pc357632@gmail.com",
    phone: "410-715-1389",
  },
  {
    id: 15,
    name: "Monocacy Donuts, Inc",
    code: 351664,
    city: "Monocacy",
    address: "1800 Monocacy Blvd, Suite A, Frederick MD 21701",
    email: "pc351664@gmail.com",
    phone: "301-378-9494",
  },
];
