import React, { useState } from "react";
import MDNavbar from "../components/header/MDNavbar";
import { Button, Container, Card, FormControl } from "react-bootstrap";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import FormikControl from "../components/formik/FormikControl";
import { locations } from "../services/locations";
import axios from "axios";
import TextInput from "../components/formik/TextInput";

const validationSchema = Yup.object({
  name: Yup.string().trim().required("Please enter your name!"),
  email: Yup.string().email().required("Please enter your email!"),
  phoneNumber: Yup.string().trim().required("Please enter your phone number!"),
  locations: Yup.array().min(1, "Please select at least one location!"),
  workingDays: Yup.array().min(1, "Please select at least one working day!"),
  designation: Yup.array().min(1, "Please select at least one designation!"),
  preferShift: Yup.string().required("Please select your prefer shift!"),
  payExpection: Yup.string().required("Please enter your pay expection!"),
  answer: Yup.number().positive().required('Please enter your answer').label('Allowed only numeric!')
});

const locationOptions = locations.map((location) => {
  return {
    key: location.address,
    value: `L${location.code}`,
  };
});

const workingDaysOptions = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
].map((day) => ({
  key: day,
  value: day,
}));

const shiftOptions = ["Morning", "Afternoon", "Evening"].map((shift) => ({
  key: shift,
  value: shift,
}));

const positionOptions = ["Team Member", "Shift Leader", "Manager"].map(
  (position) => ({
    key: position,
    value: position,
  })
);

const BASE_URL = process.env.REACT_APP_BASE_URL;

const initialValues = {
  name: "",
  email: "",
  phoneNumber: "",
  locations: [],
  workingDays: [],
  preferShift: "",
  designation: [],
  payExpection: "",
  answer: "",
};

const Career = () => {
  const [formData, setFormData] = useState(() => initialValues);
  const [loading, setLoading] = useState(false);
  const [firstNumber, setFirstNumber] = useState(() =>
    Math.floor(Math.random() * 90 + 10)
  );
  const [secondNumber, setSecondNumber] = useState(() =>
    Math.floor(Math.random() * 90 + 10)
  );
  const [answer, setAnswer] = useState(null);

  const onSubmit = async (values, form) => {
    setLoading(true);
    const correctAnswer = firstNumber + secondNumber;
    if(+values.answer !== correctAnswer) {
      toast.error('Incorrect answer, please solve the equation again');
      setFirstNumber(Math.floor(Math.random() * 90 + 10));
      setSecondNumber(Math.floor(Math.random() * 90 + 10));
      setLoading(false);
      form.setFieldError('answer', 'Incorrect answer, please solve the equation again');
      form.setFieldValue('answer', '');
      return;
    }
    values.locations = values.locations.map((location) => location.slice(1));
    delete values.answer;
    try {
      const { data } = await axios({
        method: "POST",
        url: `${BASE_URL}/v1/jobs`,
        data: values,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      toast.success(
        "Your application submitted successfully. We will contact you soon."
      );
      form.resetForm({ ...initialValues });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast.error("Something went wrong! Please try again later.");
    }
  };
  return (
    <div data-bs-theme="dark body-bg-color">
      <MDNavbar />
      <Container className="p-2">
        <Card className="m-3">
          <Card.Header>
            <h3>Fill the form and we will get back to you soon.</h3>
          </Card.Header>
          <Card.Body>
            <Formik
              enableReinitialize
              initialValues={formData}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {(formik) => (
                <>
                  <Form>
                    <FormikControl
                      control="input"
                      type="text"
                      name="name"
                      label="Name"
                      placeholder="Enter Name"
                    />
                    <FormikControl
                      control="input"
                      type="text"
                      name="email"
                      label="Email"
                      placeholder="Enter email"
                    />
                    <FormikControl
                      control="input"
                      type="text"
                      name="phoneNumber"
                      label="Phone Number"
                      placeholder="Enter contact #"
                    />
                    <FormikControl
                      control="checkbox"
                      name="locations"
                      label="Choose Your Dunkin location are you interested?"
                      options={locationOptions}
                      className="d-flex justify-content-start"
                    />
                    <FormikControl
                      control="checkbox"
                      name="workingDays"
                      label="Which day can you work?"
                      options={workingDaysOptions}
                      className="d-flex justify-content-start"
                    />
                    <FormikControl
                      control="radio"
                      name="preferShift"
                      label="Which shift you Prefer?"
                      options={shiftOptions}
                      className="d-flex justify-content-start"
                    />
                    <FormikControl
                      control="checkbox"
                      name="designation"
                      label="Which position(s) are you interested in?"
                      options={positionOptions}
                      className="d-flex justify-content-start"
                    />
                    <FormikControl
                      control="input"
                      type="text"
                      name="payExpection"
                      label="What is your Pay expectation?"
                      placeholder="Enter your pay expection"
                    />
                    <div style={{ fontWeight: "bold" }}>
                      Solve the below equation:
                    </div>
                    <div className="d-flex flex-row mt-2">
                      <div className="mt-2 me-2">
                        {firstNumber} + {secondNumber} =
                      </div>
                        <FormikControl
                          control="input"
                          type="text"
                          name="answer"
                          label=""
                          placeholder={`Sum of ${firstNumber} & ${secondNumber} is`}
                        />
                     
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? "Please wait..." : "Submit"}
                      </Button>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default Career;
