import { ErrorMessage, Field } from "formik";
import TextError from "./TextError";

const CheckboxGroup = (props) => {
	const { name, label, options, className, ...rest } = props;
	return (
		<div className="mb-3">
			<label className='form-label' style={{fontWeight: 'bold'}}>{label}</label>
			<br />
			<Field name={name} {...rest}>
				{
					({field}) => {
						return options.map(option => {
							return (
								<div className={`form-check form-check-inline ${className}`} key={option.key}>
									<input
										type='checkbox'
										id={option.value}
										{...field}
										value={option.value}
										checked={field.value.includes(option.value)}
										className="form-check-input"
									/>
									<label
										className='form-check-label ms-2'
										htmlFor={option.value}
									>
										{option.key}
									</label>
								</div>
							);
						})
					}
				}
			</Field>
			<ErrorMessage name={name} component={TextError} />
		</div>
	);
};

export default CheckboxGroup;
