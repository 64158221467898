import MDNavbar from "./components/header/MDNavbar";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Catering from "./pages/Catering";
import Career from "./pages/Career";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Catering />} />
          <Route path="/career" element={<Career />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
