import React from "react";
import { Badge, Card, Col, ListGroup, Row } from "react-bootstrap";
import { FaPhoneAlt } from "react-icons/fa";
import { locations } from "../services/locations";

const Order = () => {
  return (
    <Row className="mt-3 m-0 p-0">
      <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className="mt-3">
        <Card>
          <Card.Header>
            <h3>How to place an order?</h3>
          </Card.Header>
          <Card.Body>
            <ListGroup>
              <ListGroup.Item active>1. Find nearest location (from our locations)</ListGroup.Item>
              <ListGroup.Item>2. Call on then store number</ListGroup.Item>
              <ListGroup.Item>3. Place the order</ListGroup.Item>
            </ListGroup>
            <Card.Text className="mt-3">
              <h5>REMEMBER</h5>
              <ListGroup>
                <ListGroup.Item>
                  All orders must be placed 24 hours in advance and are for
                  pickup only
                </ListGroup.Item>
                <ListGroup.Item>Varieties may vary by location.</ListGroup.Item>
              </ListGroup>
            </Card.Text>
            <Card.Text className="mt-3">
              <h5>ALLERGEN INFORMATION</h5>
              <ListGroup>
                <ListGroup.Item>
                  Before placing your order, please inform your server if a
                  person in your party has a food allergy.
                </ListGroup.Item>
                <ListGroup.Item>
                  Catering orders are custom packed in the restaurant and may
                  contain, or have come into contact with allergens such as
                  Milk, Eggs, Fish, Shellfish, Tree nuts, Peanuts, Soy, and
                  Wheat.
                </ListGroup.Item>
                <ListGroup.Item>
                  For allergen and nutrition information, please visit{" "}
                  <a href="http://www.dunkindonuts.com" target="_blank">
                    DunkinDonuts.com
                  </a>
                </ListGroup.Item>
              </ListGroup>
            </Card.Text>
            <Card.Text className="mt-3">
              <p>
                *2000 Calories a day is used for general nutrition advice, but
                calorie needs vary. Additional nutrition information available
                upon request.
              </p>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className="mt-3">
        <Card>
          <Card.Header>
            <h3>Our Locations</h3>
          </Card.Header>
          <Card.Body>
            <ListGroup>
              {locations.map((location, index) => (
                <ListGroup.Item key={`location${index}`}>
                  <div className="ms-2 me-auto">
                    <div className="fw-bold">{location.city} Dunkin</div>
                    <div className="mt-1">{location.address}</div>
                    <div className="mt-1">
                      <FaPhoneAlt /> {location.phone}
                    </div>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default Order;
