import React from "react";
import Menu from "../components/Menu";
import Order from "../components/Order";
import MDNavbar from "../components/header/MDNavbar";
import { Container, Tabs, Tab } from "react-bootstrap";

const Catering = () => {
  return (
    <div data-bs-theme="dark body-bg-color">
      <MDNavbar />
      <Container fluid className="p-2">
        <Tabs
          defaultActiveKey="menu"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="menu" title="Menu">
            <Menu />
          </Tab>
          <Tab eventKey="order" title="How to place an order?">
            <Order />
          </Tab>
        </Tabs>
      </Container>
    </div>
  );
};

export default Catering;
