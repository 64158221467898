import React from "react";
import { Badge, Card, Col, ListGroup, Row } from "react-bootstrap";
import { menu } from "../services/menu";

const Menu = () => {
  return (
    <Row className="mt-3 m-0 p-0">
      {menu.map((item, index) => {
        return (
          <Col key={`menu${index}`} className="mt-3" xxl={3} xl={4} lg={4} md={6} sm={12} xs={12}>
            <Card>
              <Card.Header>
                <div className="ms-2 me-auto">
                  <div className="fw-bold">{item.name}</div>
                  {item.description}
                </div>
                {item?.price && (
                  <Badge bg="secondary" pill>
                    {item.price}
                  </Badge>
                )}
              </Card.Header>
              <Card.Body>
                <ListGroup>
                  {item.items.map((subitem, subindex) => {
                    return (
                      <ListGroup.Item
                        key={`sub-menu${subindex}`}
                        className="d-flex justify-content-between align-items-start"
                      >
                        <div className="ms-2 me-auto">
                          <div className="fw-bold">{subitem.name}</div>
                          {subitem?.description}
                        </div>
                        {subitem?.price && (
                          <Badge bg="secondary" pill>
                            {subitem.price}
                          </Badge>
                        )}
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
                <Card.Text className="mt-3">
                  <h5>{item.varietiesName}</h5>
                  <ListGroup variant="flush">
                    {item.varieties.map((variety, varietyIndex) => {
                      return (
                        <ListGroup.Item key={`variety${varietyIndex}`}>
                          {variety}
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>
                </Card.Text>
                {item?.additionalText && (
                  <Card.Text className="mt-3">{item.additionalText}</Card.Text>
                )}
              </Card.Body>
            </Card>
          </Col>
        );
      })}
    </Row>
  );
};

export default Menu;
