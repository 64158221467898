export const menu = [
  {
    name: "BAGELS",
    description: "Ask about our seasonal varieties",
    items: [
      {
        name: "1/2 Dozen",
        price: "$8.99",
        description: "(300-380 Cal per bagel)",
      },
      {
        name: "Dozen",
        price: "$16.99",
        description: "(300-380 Cal per bagel)",
      },
      {
        name: "Individual Cream Cheese Spread Cups",
        price: "$1.49",
        description: "(90-130 Cal)",
      },
      {
        name: "Cream Cheese Spread Tub (8oz)",
        price: "3.49",
        description: "(90 Cal per serving, about 7 servings)",
      },
    ],
    varietiesName: "Bagel Varieties",
    varieties: [
      "Cinnamon Raisin (320 Cal)",
      "Plain (300 Cal)",
      "Everything (340 Cal)",
      "Multigrain (380 Cal)",
    ],
  },
  {
    name: "MUFFINS",
    description: "Ask about our seasonal varieties",
    items: [
      {
        name: "4 Muffins",
        price: "$7.49",
        description: "(460-590 Cal per muffin)",
      },
      {
        name: "8 Muffins",
        price: "$13.99",
        description: "(460-590 Cal per muffin)",
      },
    ],
    varietiesName: "Muffin Varieties",
    varieties: [
      "Chocolate Chip (550 Cal)",
      "Blueberry (460 Cal)",
      "Corn (460 Cal)",
      "Coffee Cake (590 Cal)",
    ],
  },
  {
    name: "BEVERAGES",
    description: "Ask about our seasonal varieties",
    items: [
      {
        name: "Box O' Joe®",
        price: "$16.99",
        description: "(5 Cal per cup, 10 cups)",
      },
      {
        name: "Box O' Joe® Hot Chocolate",
        price: "$17.99",
        description: "(220 Cal per cup, 10 cups)",
      },
      {
        name: "16oz. Packaged Coffee",
        price: "$8.99",
        description: "",
      },
      {
        name: "K-Cup® Pods (12 ct.)",
        price: "$8.99",
        description: "",
      },
      {
        name: "Tea Cartons (15 ct.)",
        price: "$6.99",
        description: "",
      },
    ],
    varietiesName: "K-Cup® Pods & Packaged Coffee Varieties",
    varieties: [
      "Original Blend",
      "Dunkin’ Midnight",
      "Dunkin’ Decaf®",
      "French Vanilla",
    ],
    additionalText:
      "Box O' Joe® includes dairy, sweetener, stirrers, cups and lids. Bottled water, juice and soda are also available.",
  },
  {
    name: "DONUTS",
    description: "Ask about our seasonal varieties",
    items: [
      {
        name: "1/2 Dozen",
        price: "$7.49",
        description: "(240-370 Cal per donut)",
      },
      {
        name: "Dozen",
        price: "$13.49",
        description: "(240-370 Cal per donut)",
      },
    ],
    varietiesName: "Donut Varieties",
    varieties: [
      "Boston Kreme (270 Cal)",
      "Old Fashioned (310 Cal)",
      "Strawberry Frosted (260 Cal)",
      "Glazed Jelly (280 Cal)",
      "Glazed Chocolate (370 Cal)",
      "Glazed (240 Cal)",
      "Chocolate Frosted with Sprinkles (270 Cal)",
      "Glazed Blueberry (350 Cal)",
      "Assorted"
    ],
  },
  {
    name: "MUNCHKINS®",
    description: "Ask about our seasonal varieties",
    items: [
      {
        name: "25 Count",
        price: "$6.99",
        description: "(60 Cal per donut hole)",
      },
      {
        name: "50 Count",
        price: "$12.99",
        description: "(60 Cal per donut hole)",
      },
    ],
    varietiesName: "MUNCHKINS® Varieties",
    varieties: [
      "Glazed Chocolate (60 Cal)",
      "Glazed Blueberry (60 Cal)",
      "Powdered (60 Cal)",
      "Glazed (60 Cal)",
    ],
    additionalText: (
        <div>
            <p>Plus applicable tax.</p>
            <p>K-Cup is a trademark of Green Mountain, Inc., used with permission. 2000 Calories a day is used for general nutrition advice, but calorie needs vary. Additional nutrition available upon request.</p>
        </div>
    )
  },
  {
    name: "COMBO 1",
    description: "",
    price: "$48.99",
    items: [
      {
        name: "1 Dozen Donuts",
        description: "(240-370 Cal per donut)",
        price: "",
      },
      {
        name: "1 Dozen Bagels",
        description: "(300-380 Cal per bagel)",
        price: "",
      },
      {
        name: "2 - 8oz Cream Cheese Spread Tub",
        description: "(90 Cal per serving, about 7 servings)",
        price: "",
      },
      {
        name: "1 Box O' Joe®",
        description: "(5/220 Cal per cup, 10 cups)",
        price: "",
      },
    ],
    varietiesName: "",
    varieties: [],
    additionalText: (
      <div>
        <p>*Coffee or Hot Chocolate</p>
        <p>
          *Each Box O' Joe® serves 10 small cups of coffee or hot chocolate.
          Keep your group running by adding an extra Box O' Joe® for $16.99.
        </p>
        <p>Plus applicable tax.</p>
        <p>
          2000 Calories a day is used for general nutrition advice, but calorie
          needs vary. Additional nutrition available upon request.
        </p>
      </div>
    ),
  },
  {
    name: "COMBO 2",
    description: "",
    price: "$82.99",
    items: [
      {
        name: "1 Dozen Donuts",
        description: "(240-370 Cal per donut)",
        price: "",
      },
      {
        name: "1 Dozen Bagels",
        description: "(300-380 Cal per bagel)",
        price: "",
      },
      {
        name: "2 - 8oz Cream Cheese Spread Tub",
        description: "(90 Cal per serving, about 7 servings)",
        price: "",
      },
      {
        name: "25 ct. Munchkins®",
        description: "(60 Cal per donut hole)",
        price: "",
      },
      {
        name: "8 Muffins",
        description: "(460-590 Cal per muffin)",
        price: "",
      },
      {
        name: "2 Box O' Joe®",
        description: "(5/220 Cal per cup, 20 cups)",
        price: "",
      },
    ],
    varietiesName: "",
    varieties: [],
    additionalText: (
      <div>
        <p>*Coffee or Hot Chocolate</p>
        <p>
          *Each Box O' Joe® serves 10 small cups of coffee or hot chocolate.
          Keep your group running by adding an extra Box O' Joe® for $16.99.
        </p>
        <p>Plus applicable tax.</p>
        <p>
          2000 Calories a day is used for general nutrition advice, but calorie
          needs vary. Additional nutrition available upon request.
        </p>
      </div>
    ),
  },
  {
    name: "COMBO 3",
    description: "",
    price: "$129.99",
    items: [
      {
        name: "2 Dozen Donuts",
        description: "(240-370 Cal per donut)",
        price: "",
      },
      {
        name: "2 Dozen Bagels",
        description: "(300-380 Cal per bagel)",
        price: "",
      },
      {
        name: "4 - 8oz Cream Cheese Spread Tub",
        description: "(90 Cal per serving, about 28 servings)",
        price: "",
      },
      {
        name: "8 Muffins",
        description: "(460-590 Cal per muffin)",
        price: "",
      },
      {
        name: "3 Box O' Joe®",
        description: "(5/220 Cal per cup, 30 cups)",
        price: "",
      }
    ],
    varietiesName: "",
    varieties: [],
    additionalText: (
      <div>
        <p>*Coffee or Hot Chocolate</p>
        <p>
          *Each Box O' Joe® serves 10 small cups of coffee or hot chocolate.
          Keep your group running by adding an extra Box O' Joe® for $16.99.
        </p>
        <p>Plus applicable tax.</p>
        <p>
          2000 Calories a day is used for general nutrition advice, but calorie
          needs vary. Additional nutrition available upon request.
        </p>
      </div>
    ),
  },
];
